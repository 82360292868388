import React from 'react';
import Top from '../components/top';
import errorimg from '../assets/404.png'

const NotFound = () => {
  return (
    <>
      <Top/>
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-green-200 to-green-400">
      <div className="max-w-md p-8 bg-white rounded-lg shadow-lg text-center">
        <div className="flex justify-center mb-4">
          <img src={errorimg} alt="Error Illustration" className="w-32 h-32"/>
        </div>
        <h1 className="text-4xl font-bold text-gray-800 mb-2">ERROR 404</h1>
        <p className="text-gray-600 mb-6">Page Not Found!</p>
        <p className="text-gray-500 mb-6">Oops! Looks like something went wrong. We are working on it. Sorry!</p>
        <button className="px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600">Go Back</button>
      </div>
    </div>
  </>
  );
}

export default NotFound;
