import React from 'react';
import { Link } from 'react-router-dom';

const PausedSales = () => {
  const getPausedSales = () => {
    const keys = Object.keys(localStorage);
    return keys
      .filter((key) => key.startsWith('pausedSale-'))
      .map((key) => JSON.parse(localStorage.getItem(key)));
  };

  const pausedSales = getPausedSales();

  return (
    <div>
      <h2>Paused Sales</h2>
      <ul>
        {pausedSales.map((sale) => (
          <li key={sale.orderNumber}>
            <Link to={`/sales_rep/paused-sales/${sale.orderNumber}`} className="text-blue-500">
              {sale.orderNumber}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PausedSales;
